import { Injectable } from '@angular/core';

import { AppSettings } from '../../shared/app.settings';
import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PassengersService {
  passengerId = null;

  details = ({
    regDate: '',
    status: ''
  });
  passengerDetails = new Subject<any>();
  fetchPassengerDetails = new Subject();
  sharedpassengerDetaiils: any;

  constructor(private restApiService: RestApiService) { }

  getPassengersCount(): Observable<any> {
    return this.restApiService.get('/secure/passengers/counts');
  }

  activeDeactivePassenger(data, id?: null): Observable<any> {
    if (id !== null && id !== '' && id !== undefined) {
      return this.restApiService.put('/secure/passengers/' + id + '/status?active=' + data, 'page-center');
    }
  }

  getPassengerDriverDetails(id): Observable<any> {
    return this.restApiService.get('/secure/passengers/' + id);
  }

  addPassenger(data, id): Observable<any> {
    if (id) {
      return this.restApiService.put('/secure/passengers/' + id, data, 'page-center');
    } else {
      return this.restApiService.post('/secure/passenger', data, 'page-center');
    }
  }

  getPassengerDetails(id, eventId?): Observable<any> {
    return this.restApiService.get('/secure/passengers/' + id + "?eventId=" + (eventId ? eventId : ''));
  }

  getBookingDetails(id): Observable<any> {
    return this.restApiService.get('/secure/booking/' + id);
  }

  getStayAddress(id, eventId?): Observable<any> {
    return this.restApiService.get(`/secure/stay-location?userId=${id}&eventId=${eventId}`);
  }

  getLocationAddress(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/locations?projection=grid&activated=true&locationCategoryId=locCatId00003&offset=0&limit=9999&eventId=${eventId ? eventId : ''}`);
  }

  updateStayAddress(data, id, isUpdate) {
    if (isUpdate) {
      return this.restApiService.put(`/secure/user-stay-location/${id}`, data, 'page-center');
    } else {
      return this.restApiService.post(`/secure/user-stay-location`, data, 'page-center');
    }
  }

  communicateData(data) {
    if (data !== null) {
      this.details.regDate = data.registrationDateStr;
      this.details.status = data.status;
    }
  }

  fetchTourBooking(tourId): Observable<any> {
    return this.restApiService.get('/secure/tour/' + tourId);
  }

  getPassengerType(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/privilege-settings/data-grid?offset=0&limit=&eventId=${eventId ? eventId : ''}`, 'page-center');
  }

  getPassengerCategory(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/passenger-categories?offset=0&limit=&eventId=${eventId ? eventId : ''}`, 'page-center');
  }

  updateWallet(id, data): Observable<any> {
    return this.restApiService.post(`/secure/wallet/update/${id}`, data, 'page-center');
  }

  getPassengerAllocatedLocationData(eventId?): Observable<any> {
    return this.restApiService.get(`/secure/locations?projection=grid&isLimit=false&eventId=${eventId ? eventId : ''}`, 'page-center');
  }

  updateAlertStatus(data): Observable<any> {
    return this.restApiService.put(`/secure/passenger-alert/raise-or-remove`, data, 'page-center');
  }

  getRequestExportUserDetails(userId): Observable<any> {
    return this.restApiService.get(`/secure/gdpr/export-details/${userId}/export-xls`);
  }

  requestToDelete(userId): Observable<any> {
    // return this.restApiService.get(`/secure/gdpr/delete-user/${userId}/send-security-code`,'');
    return this.restApiService.post(`/secure/gdpr/delete-user/${userId}/send-security-code`, '', 'page-center');
  }

  confirmToDelete(userId, data): Observable<any> {
    return this.restApiService.deleteUser(`/secure/gdpr/delete-user/${userId}/account`, 'page-center', data);
  }

}
